<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/examination' }">考试</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="gray-line" />

      <div class="tip">测试结果</div>

      <div class="score theme-c">
        {{ grade }}
      </div>

      <div class="btn" @click="jump">返回首页</div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      grade: "0",
    };
  },
  methods: {
    jump() {
      this.$router.push("/home");
    },
  },

  created() {
    this.grade = sessionStorage.getItem("grade");
  },
};
</script>

<style lang="less" scoped>
.tip {
  font-size: 24px;
  margin: 30px 0;
}
.score {
  height: 65px;
  line-height: 65px;
  margin: 10px 0 20px 0;
  font-size: 72px;
}

.btn {
  cursor: pointer;
  margin: 0 auto;
  color: #fff;
  width: 260px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
  background: #db3b3d;
}
</style>
